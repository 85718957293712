// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-js": () => import("/opt/build/repo/src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-histoires-js": () => import("/opt/build/repo/src/templates/histoires.js" /* webpackChunkName: "component---src-templates-histoires-js" */),
  "component---src-templates-calendrier-js": () => import("/opt/build/repo/src/templates/calendrier.js" /* webpackChunkName: "component---src-templates-calendrier-js" */),
  "component---src-templates-progression-js": () => import("/opt/build/repo/src/templates/progression.js" /* webpackChunkName: "component---src-templates-progression-js" */),
  "component---src-templates-projets-js": () => import("/opt/build/repo/src/templates/projets.js" /* webpackChunkName: "component---src-templates-projets-js" */),
  "component---src-templates-personnages-js": () => import("/opt/build/repo/src/templates/personnages.js" /* webpackChunkName: "component---src-templates-personnages-js" */),
  "component---src-templates-pouvoirs-js": () => import("/opt/build/repo/src/templates/pouvoirs.js" /* webpackChunkName: "component---src-templates-pouvoirs-js" */),
  "component---src-templates-nouvelles-js": () => import("/opt/build/repo/src/templates/nouvelles.js" /* webpackChunkName: "component---src-templates-nouvelles-js" */),
  "component---src-templates-groupes-js": () => import("/opt/build/repo/src/templates/groupes.js" /* webpackChunkName: "component---src-templates-groupes-js" */),
  "component---src-templates-evenements-js": () => import("/opt/build/repo/src/templates/evenements.js" /* webpackChunkName: "component---src-templates-evenements-js" */),
  "component---src-templates-nombre-js": () => import("/opt/build/repo/src/templates/nombre.js" /* webpackChunkName: "component---src-templates-nombre-js" */),
  "component---src-templates-contributeurs-js": () => import("/opt/build/repo/src/templates/contributeurs.js" /* webpackChunkName: "component---src-templates-contributeurs-js" */),
  "component---src-templates-encyclopedie-js": () => import("/opt/build/repo/src/templates/encyclopedie.js" /* webpackChunkName: "component---src-templates-encyclopedie-js" */),
  "component---src-templates-chapitre-js": () => import("/opt/build/repo/src/templates/chapitre.js" /* webpackChunkName: "component---src-templates-chapitre-js" */),
  "component---src-templates-roman-js": () => import("/opt/build/repo/src/templates/roman.js" /* webpackChunkName: "component---src-templates-roman-js" */),
  "component---src-templates-projet-js": () => import("/opt/build/repo/src/templates/projet.js" /* webpackChunkName: "component---src-templates-projet-js" */),
  "component---src-templates-personnage-js": () => import("/opt/build/repo/src/templates/personnage.js" /* webpackChunkName: "component---src-templates-personnage-js" */),
  "component---src-templates-pouvoir-js": () => import("/opt/build/repo/src/templates/pouvoir.js" /* webpackChunkName: "component---src-templates-pouvoir-js" */),
  "component---src-templates-nouvelle-js": () => import("/opt/build/repo/src/templates/nouvelle.js" /* webpackChunkName: "component---src-templates-nouvelle-js" */),
  "component---src-templates-groupe-js": () => import("/opt/build/repo/src/templates/groupe.js" /* webpackChunkName: "component---src-templates-groupe-js" */),
  "component---src-templates-monde-js": () => import("/opt/build/repo/src/templates/monde.js" /* webpackChunkName: "component---src-templates-monde-js" */),
  "component---src-templates-pays-js": () => import("/opt/build/repo/src/templates/pays.js" /* webpackChunkName: "component---src-templates-pays-js" */),
  "component---src-templates-ville-js": () => import("/opt/build/repo/src/templates/ville.js" /* webpackChunkName: "component---src-templates-ville-js" */),
  "component---src-templates-theorie-js": () => import("/opt/build/repo/src/templates/theorie.js" /* webpackChunkName: "component---src-templates-theorie-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

